<template>
  <nav-header
    :title="$englishPreferred ? 'My counts' : 'Mine tællinger'"
    @synchronize-list="prepareList"
  ></nav-header>
  <div class="container">
    <loading :active="isLoading" :is-full-page="true"></loading>
    <div v-for="(collection, index) in sortedCollections" :key="collection.id">
      <div class="list-group-item nav__link mb-2" v-if="!collection.deleted">
        <router-link
          class="router-link"
          :to="{ name: 'SeeCount', params: { id: collection.id } }"
        >
          <div class="row">
            <div class="col-8">
              <h4 v-if="collection.site != null">
                <span v-if="!collection.apiId || collection.unsynched">
                  <i class="material-icons button-material-icon">upload</i>
                </span>
                <span class="item-headline">{{ collection.site.na }}</span>
              </h4>
              <br />
              <span>
                {{ collection.date }} {{ collection.startTime }} -
                {{ collection.endTime }}
              </span>
            </div>
            <div
              v-if="collection.countPoints[0] && seePoints"
              class="text-end col"
            >
              <h6>Point: {{ collection.countPoints[0].points }}</h6>
            </div>
            <div class="observation-row"></div>
          </div>
        </router-link>
      </div>
    </div>
    <div class="d-flex mt-4 justify-content-center">
      <button @click="showNextPage()" class="rounded-plus-button">+</button>
    </div>
  </div>
</template>

<script>
import collectionRepository from "../idb/repositories/collectionRepository";
import observationRepository from "../idb/repositories/observationRepository";
import countPointsRepository from "../idb/repositories/countPointsRepository";
import NavHeader from "@/components/navbars/NavHeader.vue";
import axios from "axios";
// Import component
import Loading from "vue3-loading-overlay";
// Import stylesheet
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

let localCollections = [];

export default {
  components: {
    NavHeader,
    Loading,
  },
  data() {
    return {
      navTitle: "Mine tællinger",
      collections: [],
      isLoading: true,
      seePoints: "true",
      pageSize: 10,
      currentPageNumber: 1,
    };
  },
  computed: {
    sortedCollections() {
      // Create a copy of the collections array
      const sortedArray = [...this.collections];

      // Sort the copied array based on date and startTime
      return sortedArray.sort((a, b) => {
        // Compare the dates
        const dateComparison = b.date.localeCompare(a.date);

        if (dateComparison === 0) {
          // If the dates are the same, compare the startTimes
          return b.startTime.localeCompare(a.startTime);
        } else {
          return dateComparison;
        }
      });
    },
  },
  methods: {
    async showNextPage() {
      this.isLoading = true;
      let finishedPreparing = false;
      let isOnline = window.navigator.onLine ? true : false;
      this.currentPageNumber++;
      if (isOnline) {
        // User is online
        // 1. Get collections from API
        await this.getAPICollections()
          .then(async (response) => {
            if (response.data.length > 0) {
              // 1.2. Insert each of the collections
              response.data.forEach((collection) => {
                delete collection.observations;

                let countPoint = collection.spontanPointForUser;
                delete collection.spontanPointForUser;

                collectionRepository
                  .insertCollection(collection)
                  .then((collectionId) => {
                    collection.id = collectionId;
                    collection.countPoints = [countPoint];
                    this.collections.push(collection);

                    if (countPoint != null) {
                      countPoint.datetime = new Date(countPoint.datetime);
                      this.addCountPointsToDatabase(countPoint, collectionId);
                    }
                  });
              });
            }
          })
          .catch(() => {
            console.log("Could not receive API collections");
          });
      }
      finishedPreparing = true;
      if (finishedPreparing) {
        this.isLoading = false;
      }
    },
    async prepareList() {
      let finishedPreparing = false;
      // Check if user is online
      let isOnline = window.navigator.onLine ? true : false;

      if (isOnline) {
        // User is online
        // 1. Get collections from API, and if we get response, first remove all local synchronized collections + their points and observations
        await this.getAPICollections()
          .then(async (response) => {
            if (response.data.length > 0) {
              // 1.1. Remove clear all collections and points that are synchronized
              await collectionRepository.deleteSynchedCollections();

              // 1.2. Insert each of the collections
              response.data.forEach((collection) => {
                delete collection.observations;

                let countPoint = collection.spontanPointForUser;
                delete collection.spontanPointForUser;

                collectionRepository
                  .insertCollection(collection)
                  .then((collectionId) => {
                    if (countPoint != null) {
                      countPoint.datetime = new Date(countPoint.datetime);
                      this.addCountPointsToDatabase(countPoint, collectionId);
                    }
                  });
              });
            }
          })
          .catch(() => {
            console.log("Could not receive API collections");
          });
      }
      // 2 get local collections
      if (await this.getLocalCollections()) {
        // 2.1 prepare points for local collection
        for (let collection of this.localCollections) {
          let countPoints =
            await countPointsRepository.getCountPointsByCollectionId(
              collection.id
            );
          if (countPoints != null) {
            collection.countPoints = countPoints;
          }
        }
        this.collections = this.localCollections;
      }
      // 3 now sync unysnched and deleted collection to API
      await this.synchronizeList();

      // 4 finished sync - remove loading spinner
      finishedPreparing = true;
      if (finishedPreparing) {
        this.isLoading = false;
      }
    },

    addCountPointsToDatabase(countPoint, collectionId) {
      countPointsRepository.deleteCountPointsByCollectionId(collectionId);
      countPoint.collectionId = collectionId;
      delete countPoint.id;
      countPointsRepository.insertCountPoints(countPoint);
    },

    async getLocalCollections() {
      this.localCollections = await collectionRepository.getCollections();
      return true;
    },
    getAPICollections() {
      return axios.get("/spontan/collections/mine/paginated", {
        params: {
          page: this.currentPageNumber,
          limit: this.pageSize,
        },
      });
    },
    async synchronizeList() {
      console.log("synkroniserer nye ture fra listen med API");
      for (let collection of this.collections) {
        // Prepare collection and observations for interaction with API
        let observations =
          await observationRepository.getObservationsByCollectionId(
            collection.id
          );
        let unwrappedCollection = {
          ...JSON.parse(JSON.stringify(collection)),
        };
        if (collection.apiId == null && collection.deleted != true) {
          console.log("inserting to API");
          //Insert collection in API
          axios
            .post("/spontan/collections/", unwrappedCollection)
            .then((response) => {
              let collectionApiId = response.data;
              console.log(
                "adding observations to DB, collectionApiID: " + collectionApiId
              );
              unwrappedCollection.apiId = collectionApiId;
              collection.apiId = collectionApiId;

              collectionRepository.insertCollection(unwrappedCollection);
              observations.forEach((observation) => {
                observation.collectionApiId = collectionApiId;
                // For each observation send it to API, and then set the apiId for it, and update it in local database
                axios
                  .post("/spontan/observations/", observation)
                  .then((response) => {
                    let observationApiId = response.data;
                    observation.apiId = observationApiId;
                    observation.unsynched = false;
                    observationRepository.insertObservation(observation);
                  });
              });
            });
        }
      }
      // Finished interacting with API
      return true;
    },
  },
  beforeMount() {
    this.prepareList();
  },
  mounted() {
    if ($cookies.isKey("seePoints")) {
      this.seePoints = $cookies.get("seePoints");
    }
  },
};
</script>

<style scoped>
.rounded-plus-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  background-color: #1f7aaf;
  color: #fff;
  font-size: 20px;
  line-height: 1;
  cursor: pointer;
  transition: background-color 0.3s;
}

.rounded-plus-button:hover {
  background-color: #19648f;
}
.item-headline {
  color: #51af30;
}

.nav__link {
  display: flex;
  flex-direction: column;

  flex-grow: 1;
  min-width: 50px;
  overflow: hidden;

  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  transition: background-color 0.1s ease-in-out;
  background: linear-gradient(to right, #1f7aaf, #51af30);
  background-size: 100% 5px;
  background-position: bottom 0 left 0, bottom 5px left 0;
  background-repeat: no-repeat;
}

.router-link {
  color: black;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}

.nav__link--active {
  color: #51af30;
}

.nav__icon {
  font-size: 3.5em;
}

a.nav__link:hover {
  color: #7e7f78;
}

h4 {
  background: linear-gradient(to right, #1f7aaf, #51af30);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

h6 {
  color: #1f7aaf;
}
</style>
